import { getData, patchData, postData } from "./rest";
import { CurriculumType } from "../../models/modelTypes";
import { objectToQueryString } from "../../utils/api";

export const list = async (args: Record<string, unknown>): Promise<{ items: CurriculumType[]; total: number }> =>
    await getData(`/curriculum${objectToQueryString(args)}`);

export const get = async (id: number, args: Record<string, unknown>): Promise<CurriculumType> =>
    await getData(`/curriculum/${id}${objectToQueryString(args)}`);

export const add = async (curriculum: Partial<CurriculumType>): Promise<Response> =>
    await postData(`/curriculum/`, { ...curriculum });

export const getCurriculums = async (ageRange: string): Promise<CurriculumType[]> =>
    await getData(`/curriculum/all/${ageRange}`);

export const getCurriculumById = async (ageRange: string, curriculumId: number): Promise<CurriculumType> =>
    await getData(`/curriculum/${ageRange}/${curriculumId}`);

export const update = async (id: number, curriculum: Partial<CurriculumType>): Promise<Response> =>
    await patchData(`/curriculum/${id}`, { ...curriculum });
