import React, { ChangeEvent, useState } from "react";
import { VList } from "../../core/components/VList";
import {
    createStyles,
    makeStyles,
    Box,
    Typography,
    Button,
    DialogContentText,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Collapse,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { VListItem, VListItemType } from "../../core/components/VListItem";
import { useClassroom } from "./contexts/classroom.context";
import { SimpleBlankSlate } from "../../core/components/SimpleBlankSlate";
import { SecondaryButton } from "../layout/Button/Button";
import { useAuth } from "../../contexts/auth.context";
import { ResolvedReason } from "./entities/ResolvedReason";

export const NotificationList = (props: { expandable?: boolean }): JSX.Element => {
    const { expandable } = props;
    const { hasPermission } = useAuth();
    const { classroom, setCurrentParticipant, threshold, resolveNotification, getClassroom } = useClassroom();

    const [expandList, setExpandList] = useState<{ [key: number]: boolean }>({});
    const [showDialog, setShowDialog] = useState<{ [key: number]: boolean }>({});
    const [enableResolve, setEnableResolve] = useState<{ [key: number]: boolean }>({});

    const [resolvementReasons, setResolvementReasons] = useState<{ [key: number]: string }>({});

    const useStyles = makeStyles(
        createStyles({
            root: {
                width: "100%",
                minWidth: "100%",
            },
        }),
    );

    const classes = useStyles();

    interface MoodType {
        mood: string;
        reason: string;
    }

    const handleExpand = (id: number) => {
        setExpandList((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleShowDialog = (id: number) => {
        setShowDialog((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleEnableResolve = (id: number, reason: string) => {
        setEnableResolve((prevState) => ({
            ...prevState,
            [id]: reason !== "",
        }));
    };

    const handleReasonChange = (id: number, value: string) => {
        setResolvementReasons((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const handleConfirmButton = (classroomID: number, id: number, reason: string) => {
        resolveNotification(id, reason);
        getClassroom(classroomID);
        handleShowDialog(id);
    };

    return (
        <>
            <div className={classes.root}>
                {classroom &&
                classroom.Students &&
                classroom.Students.length &&
                threshold &&
                classroom.Students.length > 0 ? (
                    <>
                        <Box>
                            <VList>
                                {classroom?.Students?.map((student) => {
                                    let negativeCount = 0;
                                    if (student.Moods) {
                                        negativeCount = student.Moods.reduce((count, mood) => {
                                            return count + (!mood.resolved ? 1 : 0);
                                        }, 0);
                                    }

                                    return {
                                        ...student,
                                        negativeCount,
                                    };
                                })
                                    .sort((a, b) => b.negativeCount - a.negativeCount)
                                    .map((s) => {
                                        let negativeMoods: MoodType[] = [];
                                        if (s.Moods) {
                                            negativeMoods = s.Moods.filter((mood) => !mood.resolved).map((mood) => ({
                                                mood: mood.mood,
                                                reason: mood.reason,
                                            }));
                                        }

                                        return (
                                            <Box key={s.id}>
                                                <VListItem
                                                    type={VListItemType.Primary}
                                                    primaryText={`${s.Participant?.name}`}
                                                    style={{ width: "100%" }}
                                                    onClick={
                                                        hasPermission("ClassroomReportView")
                                                            ? () => {
                                                                  setCurrentParticipant(s);
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            width: "30%",
                                                            marginRight: "5%",
                                                        }}
                                                    >
                                                        <Typography style={{ fontWeight: "bold", width: "11.5em" }}>
                                                            Negative emotions
                                                        </Typography>
                                                        {s.negativeCount > 0 ? (
                                                            <Button
                                                                style={{ width: "0.1em" }}
                                                                endIcon={
                                                                    expandList[s.id] ? <ExpandLess /> : <ExpandMore />
                                                                }
                                                                disableRipple
                                                                onClick={() => handleExpand(s.id)}
                                                            ></Button>
                                                        ) : null}
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "25%",
                                                            marginRight: "20%",
                                                            borderTop: "0%",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <FormControl style={{ width: "150%" }}>
                                                            <InputLabel id="timeframe-unit-label"></InputLabel>
                                                            <Select
                                                                labelId="timeframe-unit-label"
                                                                id="timeframe-unit-select"
                                                                value={resolvementReasons[s.id] || ""}
                                                                onChange={(e: ChangeEvent<{ value: unknown }>) => {
                                                                    if ((e.target.value as string) !== "") {
                                                                        handleReasonChange(
                                                                            s.id,
                                                                            e.target.value as string,
                                                                        );
                                                                        handleEnableResolve(
                                                                            s.id,
                                                                            e.target.value as string,
                                                                        );
                                                                    }
                                                                }}
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="">
                                                                    <em>Reason for notification resolvement</em>
                                                                </MenuItem>
                                                                {Object.entries(ResolvedReason).map(
                                                                    ([value, label]) => {
                                                                        return (
                                                                            <MenuItem key={value} value={value}>
                                                                                {label}
                                                                            </MenuItem>
                                                                        );
                                                                    },
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    <div style={{ marginLeft: "auto" }}>
                                                        <SecondaryButton
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                event.stopPropagation();
                                                                handleShowDialog(s.id);
                                                            }}
                                                            disabled={!enableResolve[s.id]}
                                                            style={{
                                                                backgroundColor: enableResolve[s.id]
                                                                    ? undefined
                                                                    : "#cccccc",
                                                            }}
                                                        >
                                                            Resolve
                                                        </SecondaryButton>
                                                    </div>
                                                </VListItem>
                                                <Collapse
                                                    in={!expandable || expandList[s.id]}
                                                    style={
                                                        expandable
                                                            ? {
                                                                  maxHeight: "300px",
                                                                  overflow: "auto",
                                                                  marginLeft: "11.5%",
                                                                  marginBottom: "2%",
                                                              }
                                                            : undefined
                                                    }
                                                >
                                                    {negativeMoods.map((mood, index) => (
                                                        <Typography key={index} style={{ marginTop: "10px" }}>
                                                            Mood: {mood.mood}, Reason: {mood.reason}
                                                        </Typography>
                                                    ))}
                                                </Collapse>
                                                <Dialog
                                                    open={showDialog[s.id]}
                                                    onClose={(event: React.MouseEvent<HTMLElement>) => {
                                                        handleShowDialog(s.id);
                                                        event.stopPropagation();
                                                    }}
                                                >
                                                    <DialogTitle>{"Confirm Update"}</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Are you sure you want to resolve this notification?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                handleShowDialog(s.id);
                                                                event.stopPropagation();
                                                            }}
                                                            color="primary"
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                event.stopPropagation();
                                                                handleConfirmButton(
                                                                    classroom.id,
                                                                    s.id,
                                                                    resolvementReasons[s.id],
                                                                );
                                                            }}
                                                            color="primary"
                                                        >
                                                            Confirm
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </Box>
                                        );
                                    })}
                            </VList>
                        </Box>
                    </>
                ) : (
                    <>
                        <SimpleBlankSlate
                            header={"Class List"}
                            content={`Students added to the classroom will be shown here. You can also start a student Check-In from here`}
                            extra={
                                !expandable ? (
                                    <Box>
                                        <Typography color="primary">
                                            (Available when student&apos;s have been added to the classroom)
                                        </Typography>
                                    </Box>
                                ) : undefined
                            }
                        />
                    </>
                )}
            </div>
        </>
    );
};
