import { Grid, Typography, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useOnboarding } from "../../contexts/onboarding.context";
import { WizardHeading } from "../../../../../core/components/WizardHeading";
import { PrimaryButton } from "../../../../layout/Button/Button";
import { FormActions } from "../../../../../core/components/FormActions";
import { createSubscription } from "../../../../../client/api/public/stripe";
import { useHistory } from "react-router-dom";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    RadioGroup,
    Radio,
    makeStyles,
    createStyles,
    Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { CreditCard, Receipt } from "@material-ui/icons";
import { verbalTheme } from "../../../../layout/themes/verbal.theme";
import { Product } from "../../entities/Products";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const PaymentMethod: React.FC = () => {
    const { details, plan } = useOnboarding();
    const [method, SetMethod] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();
    const stripe = useStripe();

    const [error, setError] = useState<string>();

    const handlePaymentMethodSelection = async () => {
        if (loading) return;
        try {
            setLoading(true);
            const res = await createSubscription({
                product: plan as Product,
                details: details,
                paymentMethod: method as string,
            });
            const body = await res.json();

            switch (method) {
                case "Invoice":
                    console.log("Sending for invoice");
                    history.push(`/subscription/success?subscription_id=${body?.subscriptionId}`);
                    break;

                case "Card":
                    console.log("Sending for card");
                    stripe?.redirectToCheckout({
                        sessionId: body?.subscriptionId,
                    });
                    break;
            }
        } catch (error) {
            console.log("error > ", error);
            setError("Unable to complete setup at this time. Please try again later.");
            setLoading(false);
        }
    };

    const useStyles = makeStyles(
        createStyles({
            card: {
                border: "2px solid",
                borderColor: "#32383e44",
                boxShadow: "#000000 0px 0px 0px 0px, #00000099 0px 1px 2px 0px,",
                borderRadius: "8px",
                marginTop: "1em",
                inset: -1,
                cursor: "pointer",
            },
            selectedcard: {
                borderColor: verbalTheme.palette.primary.main,
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <form>
                <WizardHeading heading={"How would you prefer to pay?"} />
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <RadioGroup
                            aria-label="Select a payment method"
                            name="paymentmethod"
                            defaultValue=""
                            onChange={(event: React.ChangeEvent, value: string) => SetMethod(value)}
                        >
                            <List>
                                <ListItem
                                    className={`${classes.card}${
                                        method === "Invoice" ? ` ${classes.selectedcard}` : ""
                                    }`}
                                    alignItems="flex-start"
                                    onClick={() => SetMethod("Invoice")}
                                >
                                    <ListItemIcon>
                                        <Receipt />
                                    </ListItemIcon>

                                    <Grid container alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <ListItemText primary="Invoice" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                We will send you an invoice at the end of your trial period to be paid
                                                by bank transfer
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Radio value="Invoice" checked={method === "Invoice"} />
                                </ListItem>
                                <ListItem
                                    className={`${classes.card}${method === "Card" ? ` ${classes.selectedcard}` : ""}`}
                                    alignItems="flex-start"
                                    onClick={() => SetMethod("Card")}
                                >
                                    <ListItemIcon>
                                        <CreditCard />
                                    </ListItemIcon>
                                    <Grid container alignItems="flex-start" spacing={2}>
                                        <Grid item xs={12}>
                                            <ListItemText primary="Credit / Debit Card" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                On the next screen you will be asked to enter your card details, once
                                                your free trial has ended you card will be automatically charged
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Radio value="Card" checked={method === "Card"} />
                                </ListItem>
                            </List>
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                        Input fields marked with an asterisk (*) are required
                    </Grid>

                    <Grid item xs={12}>
                        <FormActions
                            right={
                                <>
                                    <PrimaryButton
                                        size="large"
                                        disabled={!method || loading}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handlePaymentMethodSelection();
                                        }}
                                    >
                                        {loading ? (
                                            <>
                                                <CircularProgress size={20} color="inherit" />
                                                <span style={{ marginLeft: "1em" }}>Loading...</span>
                                            </>
                                        ) : (
                                            "Continue"
                                        )}
                                    </PrimaryButton>
                                </>
                            }
                        />
                    </Grid>
                </Grid>
            </form>
            <Box mt={4}>{error && <Alert severity="error">{error}</Alert>}</Box>
        </>
    );
};

const WrappedPaymentMethod: React.FC = () => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentMethod />
        </Elements>
    );
};

export default WrappedPaymentMethod;
