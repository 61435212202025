import { Classroom } from "common/build/prisma/client";
import { objectToQueryString } from "../../utils/api";
import { getData, patchData, postData } from "./rest";
import { EmotionTimeframe } from "../../components/classroom/entities/EmotionTimeframe";
import { EmailNotification } from "common/build/api-parameters/classroom";

export const add = async (classroomDetails: Partial<Classroom>): Promise<Response> =>
    await postData(`/classroom`, { ...classroomDetails });

export const get = async (id: number, args: Record<string, unknown>): Promise<Classroom> =>
    await getData(`/classroom/${id}${objectToQueryString(args)}`);

export const list = async (args: Record<string, unknown>): Promise<{ items: Classroom[]; total: number }> =>
    await getData(`/classroom${objectToQueryString(args)}`);

export const update = async (id: number, classroomDetails: Partial<Classroom>): Promise<Response> =>
    await patchData(`/classroom/${id}`, { ...classroomDetails });

export const addParticipant = async (
    id: number,
    studentDetails: {
        id: number;
        name?: string;
        email?: string | null;
        phoneNumber?: string | null;
        organisationId?: number;
    },
): Promise<Response> => await postData(`/classroom/${id}/student`, { ...studentDetails });

export const addTeacher = async (id: number, userId: number): Promise<Response> =>
    await postData(`/classroom/${id}/teacher`, { userId });

export const removeTeacher = async (id: number, userId: number): Promise<Response> =>
    await postData(`/classroom/${id}/teacher/remove`, { userId });

export const addMood = async (id: number, participantDetails: Record<string, unknown>): Promise<Response> =>
    await postData(`/classroom/${id}/student/add/mood`, { ...participantDetails });

export const editClassroom = async (id: number, classroomId: number): Promise<Response> =>
    await postData(`/classroom/${id}/edit`, { classroomId });

export const updateThreshold = async (
    id: number,
    emotionCount: number,
    emotionFrequency: number,
    emotionTimeframe: EmotionTimeframe,
): Promise<Response> =>
    await postData(`/classroom/${id}/threshold/update`, { id, emotionCount, emotionFrequency, emotionTimeframe });

export const getThreshold = async (id: number): Promise<Response> =>
    await postData(`/classroom/${id}/threshold/get`, { id });

export const emailTeacherThreshold = async (
    id: number,
    email: string,
    studentID: number,
    thresholdID: number,
): Promise<Response> => await postData(`/classroom/${id}/email/teacher/threshold`, { email, studentID, thresholdID });

export const resolveMood = async (id: number, reason: string): Promise<Response> =>
    await postData(`/classroom/${id}/mood/update`, { id, reason });

export const getResolvedMoods = async (id: number): Promise<Response> =>
    await postData(`/classroom/${id}/mood/get`, { id });

export const updateEmailNotification = async (id: number, field: EmailNotification, flag: boolean): Promise<Response> =>
    await postData(`/classroom/${id}/email/update`, { id, field, flag });

export const getEmailNotification = async (id: number): Promise<Response> =>
    await postData(`/classroom/${id}/email/get`, { id });

export const emailAdminThreshold = async (
    id: number,
    email: string,
    thresholdID: number,
    studentCount: number,
): Promise<Response> => await postData(`/classroom/${id}/email/admin/threshold`, { email, thresholdID, studentCount });

export const findAdmins = async (id: number): Promise<Response> => await postData(`/classroom/${id}/admin`, { id });

export const emailAdminFlags = async (id: number, adminEmail: string, teacherEmail: string): Promise<Response> =>
    await postData(`/classroom/${id}/email/admin/flags`, { id, adminEmail, teacherEmail });

//export const deleteClassroom = async (id: number): Promise<Response> => deleteData(`/classroom/${id}`);
