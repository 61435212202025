"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateAgeRanges = void 0;
const generateAgeRanges = () => {
    const ageRanges = [];
    for (let i = 4; i < 17; i++) {
        ageRanges.push(`${i}-${i + 1}`);
    }
    return ageRanges;
};
exports.generateAgeRanges = generateAgeRanges;
// ageRanges.push("18+");
