import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { ClassroomProvider } from "./contexts/classroom.context";
import { Classrooms } from "./screens/Classrooms";
import { BaseRoute } from "../routing/PrivateRoute";
import { CreateClassroom } from "./screens/CreateClassroom";
import { ViewClassroom } from "./screens/ViewClassroom";
import { FetchProvider } from "../../core/components/crud/contexts/fetch.context";
import { get } from "../../client/api/classroom";
import { get as getProgramme } from "../../client/api/programme";
import { ProgrammeProvider } from "../programme/contexts/programme.context";
import { CreateClassProgramme } from "./screens/CreateClassProgramme";
import { ViewClassProgramme } from "./screens/ViewClassProgramme";
//import { ClassSessionScreen } from "./session/ClassSessionScreen";

export const ClassroomScreen = (): JSX.Element => {
    const { path } = useRouteMatch();

    return (
        <ClassroomProvider>
            <FetchProvider noun="Classroom" getItem={get}>
                <Switch>
                    <BaseRoute path={`${path}/view/:id`} exact permission="ClassroomView" component={ViewClassroom} />
                    <BaseRoute path={`${path}`} exact permission={"ClassroomView"} component={Classrooms} />
                    <BaseRoute path={`${path}/add`} exact permission={"ClassroomCreate"} component={CreateClassroom} />
                    <ProgrammeProvider>
                        <BaseRoute
                            path={`${path}/view/:id/add/:paramId?`}
                            permission="ProgrammeCreate"
                            component={CreateClassProgramme}
                        />
                        <FetchProvider noun="Programme" getItem={getProgramme}>
                            <BaseRoute
                                path={`${path}/view/:id/view/:programmeId`}
                                exact
                                permission="ProgrammeView"
                                component={() => <ViewClassProgramme />}
                            />
                        </FetchProvider>
                    </ProgrammeProvider>
                </Switch>
            </FetchProvider>
        </ClassroomProvider>
    );
};
