import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Box, Typography, Button, Collapse, CircularProgress } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useClassroom } from "./contexts/classroom.context";
import { SimpleBlankSlate } from "../../core/components/SimpleBlankSlate";
import { startOfWeek, formatISO } from "date-fns";
import { MoodType } from "./../../models/modelTypes";

export const ResolvedNotifications = (): JSX.Element => {
    const { classroom, getResolvedNotifications, resolvedMoods } = useClassroom();

    const [expandList, setExpandList] = useState<{ [key: string]: boolean }>({});

    const [loading, setLoading] = useState(true);

    const [moodsByWeekStarting, setMoodsByWeekStarting] = useState<{ [key: string]: AugmentedMood[] }>({});

    interface AugmentedMood extends MoodType {
        weekStarting: string;
        participantName?: string;
    }

    interface MoodsByWeek {
        [key: string]: AugmentedMood[];
    }

    useEffect(() => {
        if (classroom) {
            getResolvedNotifications(classroom.id).finally(() => setLoading(false));
        }
    }, [classroom, getResolvedNotifications]);

    const useStyles = makeStyles(
        createStyles({
            root: {
                width: "100%",
                minWidth: "100%",
            },
            weekHeader: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1em",
                fontWeight: "bold",
                fontSize: "150%",
            },
            moodInfo: {
                marginBottom: "1em",
            },
            button: {
                marginLeft: "auto",
            },
            collapse: {
                marginTop: "0em",
                marginBottom: "2em",
            },
        }),
    );

    const classes = useStyles();

    useEffect(() => {
        if (resolvedMoods) {
            const groupedByWeek = resolvedMoods.reduce<MoodsByWeek>((acc, mood) => {
                const date = new Date(mood.date);
                const weekStart = formatISO(startOfWeek(date, { weekStartsOn: 1 }), { representation: "date" });

                if (!acc[weekStart]) {
                    acc[weekStart] = [];
                }
                acc[weekStart].push({
                    ...mood,
                    weekStarting: weekStart,
                    participantName: `${mood.Participant?.Participant?.name}`,
                });
                return acc;
            }, {});

            setMoodsByWeekStarting(groupedByWeek);
        }
    }, [resolvedMoods]);

    if (loading) {
        return <CircularProgress />;
    }

    const handleExpand = (id: string) => {
        setExpandList((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    // deploy prod
    return (
        <div className={classes.root}>
            {classroom && Object.keys(moodsByWeekStarting).length > 0 ? (
                Object.entries(moodsByWeekStarting).map(([weekStart, moods]) => (
                    <Box key={weekStart}>
                        <Typography className={classes.weekHeader}>
                            Week Starting: {weekStart}
                            <Button className={classes.button} onClick={() => handleExpand(weekStart)}>
                                {expandList[weekStart] ? <ExpandLess /> : <ExpandMore />}
                            </Button>
                        </Typography>
                        <Collapse in={expandList[weekStart] || false} className={classes.collapse}>
                            {moods.map((mood, index) => (
                                <Typography key={index} className={classes.moodInfo}>
                                    <strong>Student:</strong> {mood.Participant?.Participant?.name}
                                    <br />
                                    <strong>Mood: </strong>
                                    {mood.mood}
                                    <br />
                                    <strong>Reason: </strong>
                                    {mood.reason}
                                    <br />
                                    <strong>Resolved reason: </strong>
                                    {mood.resolvedReason}
                                </Typography>
                            ))}
                        </Collapse>
                    </Box>
                ))
            ) : (
                <SimpleBlankSlate
                    header="Resolved notifications"
                    content="Resolved student negative emotions will be shown here."
                />
            )}
        </div>
    );
};
