import { Avatar, Box, Card, CardContent, Grid, Hidden, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../core/components/Breadcrumb";
import { useCollection } from "../../hooks/useCollection";
import { useMobile } from "../../hooks/useMobile";
import { useStory } from "../../hooks/useStory";
import { WeekType } from "../../models/modelTypes";
import Spinner from "../layout/Spinner";
import { verbalTheme } from "../layout/themes/verbal.theme";
import { getColourClass } from "./BrowseHome";
import { useHomeStyles } from "./Home.style";
import { getColourCode } from "./HomeViewItem";
import { VList } from "../../core/components/VList";
import { VListItem, VListItemType } from "../../core/components/VListItem";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { StorySynopsis } from "../session/components/StorySynopsis";

export const ViewCollectionStory = (): JSX.Element => {
    const { storyId, id } = useParams<{ id: string; storyId: string }>();
    const { isTablet, isMobile } = useMobile();
    const { mappedStory, getMappedStory } = useStory();
    const { collection, getCollection } = useCollection();

    const history = useHistory();

    const [week, setWeek] = useState<WeekType>();
    const myRef = React.createRef<HTMLDivElement>();

    const scrollToTop = () => {
        myRef.current?.scrollIntoView();
    };

    useEffect(() => {
        if (parseInt(storyId)) {
            getMappedStory(parseInt(storyId));
        }
    }, [storyId]);

    useEffect(() => {
        if (parseInt(id)) {
            getCollection(parseInt(id));
        }
    }, [id]);

    useEffect(() => {
        if (collection && collection.Weeks && collection.Weeks.length > 0 && mappedStory && mappedStory.order) {
            const currentWeek = collection.Weeks.filter((week) => week.order === mappedStory.order)[0];
            if (currentWeek) setWeek(currentWeek);
        }
    }, [collection, mappedStory]);

    const classes = useHomeStyles();

    return (
        <div ref={myRef}>
            <Box display={"flex"} width="100%" height="100%" style={{ overflow: "hidden" }} pb={4}>
                {mappedStory && mappedStory.StoryCollection && mappedStory.Story ? (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {!isTablet && isMobile && <div className={classes.toolbar} />}
                                <Box pl={!isTablet && isMobile ? 2 : 4} pt={!isTablet && isMobile ? 6 : 2}>
                                    <Breadcrumb
                                        crumbs={[
                                            {
                                                name: `${"Explore"}`,
                                                url: `${"/explore"}`,
                                            },
                                            {
                                                name: mappedStory?.StoryCollection?.Curriculum?.name ?? "Curriculum",
                                                url: `/explore/curriculum/${mappedStory?.StoryCollection?.Curriculum?.id}`,
                                            },
                                            {
                                                name: mappedStory.StoryCollection?.name,
                                                url: `/explore/curriculum/${mappedStory.StoryCollection?.Curriculum?.id}/collection/${mappedStory.StoryCollection.id}`,
                                            },
                                        ]}
                                        current={""}
                                    />
                                </Box>
                                <Box pl={!isTablet && isMobile ? 2 : 4} pt={2}>
                                    <Typography variant="h1">{mappedStory?.Story?.storyName}</Typography>
                                </Box>
                                <Box px={!isTablet && isMobile ? 0 : 4}>
                                    <StorySynopsis story={mappedStory} week={week} />
                                </Box>
                            </Grid>
                            <Hidden only={["xs", "sm"]}>
                                <Grid
                                    id="stories-all"
                                    item
                                    xs={12}
                                    container
                                    spacing={3}
                                    style={{ paddingLeft: verbalTheme.spacing(5) }}
                                    alignItems="center"
                                >
                                    <Grid item xs={12}>
                                        <Box>
                                            <Typography variant="h4">Other stories in this collection</Typography>
                                        </Box>
                                    </Grid>
                                    {collection && collection.Stories && collection.Stories.length > 0
                                        ? collection.Stories.map((ms) => {
                                              let isCurrent = false;
                                              if (ms.id === mappedStory.id) {
                                                  isCurrent = true;
                                              }
                                              return (
                                                  <Grid item key={ms.id}>
                                                      <Card
                                                          square
                                                          style={{
                                                              minHeight: "100px",
                                                              height: "100%",
                                                              width: "100%",
                                                          }}
                                                          onClick={() => {
                                                              history.push(`/explore/collection/${id}/story/${ms.id}`);
                                                              scrollToTop();
                                                          }}
                                                          elevation={2}
                                                      >
                                                          <CardContent
                                                              style={{
                                                                  height: "100%",
                                                                  padding: 0,
                                                                  display: "flex",
                                                                  minWidth: "200px",
                                                              }}
                                                          >
                                                              <Box
                                                                  bgcolor={`${
                                                                      !isCurrent
                                                                          ? getColourClass({
                                                                                colour: ms.Story?.colour ?? "Red",
                                                                            })
                                                                          : "gray"
                                                                  }`}
                                                                  position="relative"
                                                                  height="100%"
                                                                  width="100%"
                                                              >
                                                                  <Box
                                                                      height={"100%"}
                                                                      minHeight="125px"
                                                                      width={"100%"}
                                                                      minWidth="125px"
                                                                      style={
                                                                          isCurrent
                                                                              ? { filter: "grayscale(100%) blur(5px)" }
                                                                              : undefined
                                                                      }
                                                                  >
                                                                      <Avatar
                                                                          alt="Story"
                                                                          style={{
                                                                              margin: verbalTheme.spacing(2),
                                                                              width: verbalTheme.spacing(7),
                                                                              height: verbalTheme.spacing(7),
                                                                              color: verbalTheme.palette.getContrastText(
                                                                                  getColourCode(
                                                                                      ms?.Story?.colour ?? "Red",
                                                                                  ),
                                                                              ),
                                                                              backgroundColor: getColourCode(
                                                                                  ms?.Story?.colour ?? "Red",
                                                                              ),
                                                                          }}
                                                                      >
                                                                          {ms?.Story?.storyName
                                                                              ?.trim()
                                                                              ?.split(" ")?.[0]
                                                                              ?.charAt(0)}
                                                                          {ms?.Story?.storyName
                                                                              ?.trim()
                                                                              ?.split(" ")?.[1]
                                                                              ?.charAt(0)}
                                                                      </Avatar>
                                                                  </Box>

                                                                  <Box
                                                                      position={"absolute"}
                                                                      bottom={0}
                                                                      bgcolor={"rgba(0,0,0, 0.5)"}
                                                                      width="100%"
                                                                  >
                                                                      <Box m={1} display="flex" alignItems={"center"}>
                                                                          <Typography
                                                                              style={{
                                                                                  color: verbalTheme.palette.background
                                                                                      .default,
                                                                              }}
                                                                          >
                                                                              {isCurrent
                                                                                  ? "Current Story"
                                                                                  : `Story ${ms.order}`}
                                                                          </Typography>
                                                                      </Box>
                                                                  </Box>
                                                              </Box>
                                                          </CardContent>
                                                      </Card>
                                                  </Grid>
                                              );
                                          })
                                        : null}
                                </Grid>
                            </Hidden>
                            <Hidden only={["md", "lg", "xl"]}>
                                <Grid item xs={12}>
                                    <Box bgcolor="secondaryShade.main" width={1} px={!isTablet && isMobile ? 2 : 3}>
                                        <Typography
                                            variant="h4"
                                            style={{
                                                paddingTop: verbalTheme.spacing(3),
                                                paddingBottom: verbalTheme.spacing(1),
                                            }}
                                        >
                                            Other stories in this collection
                                        </Typography>
                                        <VList>
                                            {collection && collection.Stories && collection.Stories.length > 0
                                                ? collection.Stories?.map((item) => {
                                                      let isCurrent = false;
                                                      if (item.id === mappedStory.id) {
                                                          isCurrent = true;
                                                      }
                                                      return (
                                                          <Box mb={1} key={item.id}>
                                                              <Paper elevation={0}>
                                                                  <VListItem
                                                                      type={VListItemType.Primary}
                                                                      primaryText={`${item.Story?.storyName} ${
                                                                          isCurrent ? "(Current)" : ""
                                                                      }`}
                                                                      textProps={{ color: "primary" }}
                                                                      button
                                                                      disabled={isCurrent}
                                                                      avatar={item.order}
                                                                      onClick={() => {
                                                                          history.push(
                                                                              `/explore/collection/${collection.id}/story/${item.id}`,
                                                                          );
                                                                      }}
                                                                  >
                                                                      <ArrowForwardIosIcon color="primary" />
                                                                  </VListItem>
                                                              </Paper>
                                                          </Box>
                                                      );
                                                  })
                                                : null}
                                        </VList>
                                    </Box>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </>
                ) : (
                    <Box m="auto" justifyContent="center">
                        <Spinner />
                    </Box>
                )}
            </Box>
        </div>
    );
};
