import { Avatar, Box, Card, CardContent, Grid, Hidden, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { VList } from "../../core/components/VList";
import { VListItem, VListItemType } from "../../core/components/VListItem";
import { useCollection } from "../../hooks/useCollection";
import { useMobile } from "../../hooks/useMobile";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { getColourCode, HomeViewItem } from "./HomeViewItem";
import Spinner from "../layout/Spinner";
import { verbalTheme } from "../layout/themes/verbal.theme";
import Breadcrumb from "../../core/components/Breadcrumb";
import { useAuth } from "../../contexts/auth.context";
import { useOrganisation } from "../../hooks/useOrganisation";
import { getColourClass } from "./BrowseHome";
import { useHomeStyles } from "./Home.style";
import { Alert } from "@material-ui/lab";
import StoryIcon from "../../img/storiesIcon.png";

export const ViewCollection = (): JSX.Element => {
    const { id, collectionId } = useParams<{ id: string; collectionId: string }>();
    const { collection, getCollection } = useCollection();
    const { isMobile, isTablet } = useMobile();
    const { user } = useAuth();
    const { organisation, getOrganisation } = useOrganisation();

    const history = useHistory();

    useEffect(() => {
        getCollection(parseInt(collectionId));
    }, [collectionId]);

    useEffect(() => {
        if (user) {
            getOrganisation(user.id);
        }
    }, [user]);

    const classes = useHomeStyles();

    return (
        <Box display={"flex"} width="100%" height="100%" style={{ overflowX: "hidden" }}>
            {collection ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {!isTablet && isMobile && <div className={classes.toolbar} />}
                            <Box pl={!isTablet && isMobile ? 2 : 4} pt={!isTablet && isMobile ? 6 : 2}>
                                <Breadcrumb
                                    crumbs={[
                                        {
                                            name: `${"Explore"}`,
                                            url: `${"/explore"}`,
                                        },
                                        {
                                            name: collection?.Curriculum?.name ?? "Curriculum",
                                            url: `/explore/curriculum/${id}`,
                                        },
                                    ]}
                                    current={``}
                                />
                            </Box>
                            <div id="collection-details" style={{ height: "95%" }}>
                                <HomeViewItem
                                    title={`${collection.name}`}
                                    description={collection.description}
                                    colour={collection.colour}
                                    ageRange={collection.ageRange}
                                />
                            </div>
                        </Grid>
                        <Hidden only={["xs", "sm"]}>
                            <Grid item xs={12} container spacing={2} id="collection-stories">
                                <Grid item xs={12}>
                                    {organisation && organisation.Tier.tier === "Demo" && (
                                        <div className={classes.toolbar} />
                                    )}
                                    <Typography variant="h4" style={{ marginLeft: verbalTheme.spacing(3) }}>
                                        Stories in this collection
                                    </Typography>
                                </Grid>
                                {collection && collection.Stories && collection.Stories.length > 0 ? (
                                    collection.Stories?.map((item) => (
                                        <Grid item lg={4} md={6} xs={12} key={item.id} style={{ display: "flex" }}>
                                            {item?.Story && (
                                                <Card
                                                    style={{
                                                        minHeight: "150px",
                                                        height: "100%",
                                                        width: "100%",
                                                        marginLeft: verbalTheme.spacing(3),
                                                        marginRight: verbalTheme.spacing(3),
                                                    }}
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: `/explore/collection/${collection.id}/story/${item.id}`,
                                                        });
                                                    }}
                                                    elevation={2}
                                                >
                                                    <CardContent
                                                        style={{
                                                            height: "100%",
                                                            padding: 0,
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <Box
                                                            bgcolor={`${getColourClass({
                                                                colour: item.Story.colour,
                                                            })}`}
                                                            position="relative"
                                                            height="100%"
                                                            width="100%"
                                                        >
                                                            <Box
                                                                height={"100%"}
                                                                width={"100%"}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    filter: "contrast(150%) saturate(110%)",
                                                                }}
                                                            >
                                                                <Avatar
                                                                    alt="Collection"
                                                                    src={StoryIcon}
                                                                    style={{
                                                                        color: verbalTheme.palette.getContrastText(
                                                                            getColourCode(item?.Story?.colour ?? "Red"),
                                                                        ),
                                                                        backgroundColor: getColourCode(
                                                                            item?.Story?.colour ?? "Red",
                                                                        ),
                                                                        //margin: verbalTheme.spacing(2),
                                                                        width: verbalTheme.spacing(8),
                                                                        height: verbalTheme.spacing(8),
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box
                                                                position={"absolute"}
                                                                bottom={0}
                                                                bgcolor={"rgba(0,0,0, 0.5)"}
                                                                width="100%"
                                                            >
                                                                <Box m={1} display="flex" alignItems={"center"}>
                                                                    <Typography
                                                                        style={{
                                                                            color: verbalTheme.palette.background
                                                                                .default,
                                                                        }}
                                                                    >
                                                                        {`Story ${item.order}`}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid item xs={12}>
                                        <Alert severity="warning">Stories coming soon...</Alert>
                                    </Grid>
                                )}
                            </Grid>
                        </Hidden>

                        <Hidden only={["md", "lg", "xl"]}>
                            <Grid item xs={12}>
                                <Box bgcolor="secondaryShade.main" width={1} px={2}>
                                    <Typography
                                        variant="h4"
                                        style={{
                                            paddingTop: verbalTheme.spacing(3),
                                            paddingBottom: verbalTheme.spacing(1),
                                        }}
                                    >
                                        Stories in this collection
                                    </Typography>
                                    <VList>
                                        {collection.Stories && collection.Stories.length > 0 ? (
                                            collection.Stories?.map((item) => (
                                                <Box mb={1} key={item.id}>
                                                    <Paper elevation={0}>
                                                        <VListItem
                                                            type={VListItemType.Primary}
                                                            primaryText={item.Story?.storyName}
                                                            textProps={{ color: "primary" }}
                                                            button
                                                            avatar={item.order}
                                                            onClick={() => {
                                                                history.push({
                                                                    pathname: `/explore/collection/${collection.id}/story/${item.id}`,
                                                                });
                                                            }}
                                                        >
                                                            <ArrowForwardIosIcon color="primary" />
                                                        </VListItem>
                                                    </Paper>
                                                </Box>
                                            ))
                                        ) : (
                                            <Alert severity="warning">Stories coming soon...</Alert>
                                        )}
                                    </VList>
                                </Box>
                            </Grid>
                        </Hidden>
                    </Grid>
                </>
            ) : (
                <Box m="auto" justifyContent="center">
                    <Spinner />
                </Box>
            )}
        </Box>
    );
};
