import React, { useEffect, useState } from "react";
import { VList } from "../../../core/components/VList";
import { createStyles, makeStyles, Box, Typography, Button } from "@material-ui/core";
// import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ClassroomType, MoodType, ParticipantToClassroomType } from "../../../models/modelTypes";
import { VListItem, VListItemType } from "../../../core/components/VListItem";
import { StudentProfile } from "../students/StudentProfile";
import { StudentCheckIn } from "../students/StudentCheckIn";
import { useClassroom } from "../contexts/classroom.context";
import { SimpleBlankSlate } from "../../../core/components/SimpleBlankSlate";
import barchartImg from "../../../img/barchart.svg";
// import { PrimaryButton } from "../../layout/Button/Button";
import { useAuth } from "../../../contexts/auth.context";
import { useHistory, useLocation } from "react-router-dom";
import { ResolvedReason } from "common/build/prisma/client";

export const StudentMonitoring = (props: { expandable?: boolean }): JSX.Element => {
    const { expandable } = props;
    const { hasPermission } = useAuth();
    const { classroom, addStudentMood, currentParticipant, setCurrentParticipant, threshold, checkThreshold } =
        useClassroom();

    const [openProfile, setOpenProfile] = useState<boolean>(false);
    const [nextParticipant, setNextParticipant] = useState<ParticipantToClassroomType>();
    const [prevParticipant, setPrevParticipant] = useState<ParticipantToClassroomType>();
    const [finishedSessionId, setFinishedSessionId] = useState<number>();
    const [moodType, setMoodType] = useState<string>("Registration");

    const [openCheckIn, setOpenCheckIn] = useState<boolean>(false);
    // const [expandList, setExpandList] = useState<boolean>(false);

    const location = useLocation<{ startCheckIn?: boolean; checkinSessionId?: number }>();
    const history = useHistory();
    const startCheckIn = location.state?.startCheckIn;
    const checkInSessionId = location.state?.checkinSessionId;

    const checkForMoodTypesToday = (type: string, sessionId: number | null): boolean =>
        classroom?.Students?.some(
            (s) =>
                s.Moods?.some((m) => {
                    const today = new Date();
                    const moodDate = new Date(m.date);
                    if (
                        m.type === type &&
                        (sessionId === null || m.sessionId === sessionId) &&
                        today.getDate() === moodDate.getDate() &&
                        today.getMonth() === moodDate.getMonth() &&
                        today.getFullYear() === moodDate.getFullYear()
                    ) {
                        return true;
                    }
                }),
        ) ?? false;

    // const isRegisterTakenToday: boolean = checkForMoodTypesToday("Registration", null);

    useEffect(() => {
        if (openCheckIn) {
            history.push({ search: "?checkIn" });
        } else {
            history.push({ search: "" });
        }
    }, [openCheckIn]);

    useEffect(() => {
        if ((classroom?.Students?.length ?? 0) > 0 && startCheckIn) {
            if (checkInSessionId) {
                if (!checkForMoodTypesToday("Session", checkInSessionId)) {
                    setCurrentParticipant(classroom?.Students?.[0]);
                    setFinishedSessionId(checkInSessionId);
                    setOpenCheckIn(true);
                    setMoodType("Session");
                }
            } else {
                setMoodType("Registration");
            }
        }
    }, [startCheckIn]);

    useEffect(() => {
        if (currentParticipant && classroom?.Students?.length) {
            setNextParticipant(
                classroom?.Students?.findIndex((s) => s.id === currentParticipant?.id) + 1 < classroom?.Students?.length
                    ? classroom?.Students[classroom?.Students?.findIndex((s) => s.id === currentParticipant?.id) + 1]
                    : undefined,
            );

            setPrevParticipant(
                classroom?.Students?.findIndex((s) => s.id === currentParticipant?.id) - 1 > -1
                    ? classroom?.Students[classroom?.Students?.findIndex((s) => s.id === currentParticipant?.id) - 1]
                    : undefined,
            );
        }
    }, [currentParticipant]);

    const handleNextProfile = () => {
        if (classroom?.Students && classroom.Students.length > 0) {
            if (nextParticipant) {
                setCurrentParticipant(nextParticipant);
            } else {
                setCurrentParticipant(classroom.Students[0]);
            }
        }
    };

    const handlePreviousProfile = () => {
        if (classroom?.Students && classroom.Students.length > 0) {
            if (prevParticipant) {
                setCurrentParticipant(prevParticipant);
            } else {
                setCurrentParticipant(classroom.Students.at(-1));
            }
        }
    };

    const handleMoodTrackerClose = () => {
        setFinishedSessionId(undefined);
        setOpenCheckIn(false);
        setMoodType("Registration");
    };

    const handleSetMood = async (mood: string, reason: string): Promise<ClassroomType> => {
        const negativeMoods = ["Sad", "Angry", "Worried"];

        if (!classroom?.id) {
            throw new Error("No classroom ID available");
        }

        let studentMood = {
            id: currentParticipant?.id,
            mood: mood,
            reason: reason,
            trigger: false,
            isPresent: true,
            participantId: currentParticipant?.participantId,
            sessionId: finishedSessionId,
            type: moodType,
            resolved: true,
            resolvedReason: ResolvedReason.NA,
            requiresResolution: true,
        } as Partial<MoodType>;

        if (negativeMoods.includes(mood)) {
            studentMood = {
                ...studentMood,
                trigger: true,
                resolved: false,
                resolvedReason: ResolvedReason.Unresolved,
                requiresResolution: true,
            };
        }
        setCurrentParticipant(undefined);
        try {
            const updatedClassroom = await addStudentMood(classroom.id, studentMood);
            return updatedClassroom;
        } catch (error) {
            console.error("Failed to add mood:", error);
            throw error;
        }
    };

    const useStyles = makeStyles(
        createStyles({
            root: {
                width: "100%",
                minWidth: "100%",
            },
            listContainer: {
                maxHeight: "250px",
                overflow: "auto",
            },
        }),
    );

    const classes = useStyles();

    // deploy prod
    return (
        <>
            <div className={classes.root}>
                {classroom &&
                classroom.Students &&
                classroom.Students.length &&
                threshold &&
                classroom.Students.length > 0 ? (
                    <>
                        <Box className={classes.listContainer}>
                            <VList>
                                {classroom?.Students.sort(
                                    (a, b) => checkThreshold(a, threshold) - checkThreshold(b, threshold),
                                ) // Sort students by threshold result ascending
                                    .map((s) => (
                                        <Box key={s.id}>
                                            <VListItem
                                                type={VListItemType.Primary}
                                                primaryText={`${s.Participant?.name}`}
                                                textProps={{ style: { fontWeight: "bold" } }}
                                                onClick={
                                                    hasPermission("ClassroomReportView")
                                                        ? () => {
                                                              setCurrentParticipant(s);
                                                              setOpenProfile(true);
                                                          }
                                                        : undefined
                                                }
                                                button={hasPermission("ClassroomReportView") ? true : undefined}
                                                style={{
                                                    fontWeight: "bold",
                                                    color:
                                                        checkThreshold(s, threshold) > 1
                                                            ? "black"
                                                            : checkThreshold(s, threshold) < 0
                                                            ? "red"
                                                            : "orange",
                                                }}
                                            ></VListItem>
                                        </Box>
                                    ))}
                            </VList>
                        </Box>
                        <Box display={"flex"} justifyContent={"center"}>
                            <Button
                                variant={"outlined"}
                                color="primary"
                                onClick={() => {
                                    setCurrentParticipant(classroom?.Students?.[0]);
                                    setOpenCheckIn(true);
                                }}
                            >
                                Open Wellbeing Check-In
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <SimpleBlankSlate
                            image={<img src={barchartImg} />}
                            header={"Class List"}
                            content={`Students added to the classroom will be shown here. You can also start a student Check-In from here`}
                            extra={
                                !expandable ? (
                                    <Box>
                                        <Typography color="primary">
                                            (Available when student&apos;s have been added to the classroom)
                                        </Typography>
                                    </Box>
                                ) : undefined
                            }
                        />
                    </>
                )}

                <StudentCheckIn
                    sessionId={checkInSessionId}
                    moodType={moodType}
                    open={openCheckIn}
                    setOpen={setOpenCheckIn}
                    handleSetMood={handleSetMood}
                    moodTrackerClose={handleMoodTrackerClose}
                />

                <StudentProfile
                    open={openProfile}
                    setOpenProfile={setOpenProfile}
                    handleNextProfile={handleNextProfile}
                    handlePreviousProfile={handlePreviousProfile}
                />
            </div>
        </>
    );
};
