import React, { useState } from "react";
import { Box, Typography, Button, Dialog } from "@material-ui/core";
import { HeadlineFigureWidget } from "../../../dashboard/Widgets/Headlines/HeadlineFigureWidget";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import { useClassroom } from "../../contexts/classroom.context";
import { NotificationList } from "../../NotificationList";

export const ClassCountWidget = (): JSX.Element => {
    const { classroom } = useClassroom();
    const [showDialog, setShowDialog] = useState(false);

    let negativeCount = 0;

    classroom?.Students?.map((s) => {
        s?.Moods?.forEach((mood) => {
            if (!mood.resolved) {
                negativeCount++;
            }
        });
    });

    return (
        <>
            <HeadlineFigureWidget>
                <Box>
                    <SpinWhileFetching>
                        <Typography variant="h5">Unaddressed notifications:</Typography>
                        <Typography variant="h1" style={{ color: "red", marginTop: "10%" }}>
                            {negativeCount}
                        </Typography>
                    </SpinWhileFetching>
                </Box>
                <Button size={"small"} variant="contained" color="primary" onClick={() => setShowDialog(true)}>
                    View Notifications
                </Button>
            </HeadlineFigureWidget>
            <Dialog
                open={showDialog !== false}
                onClose={() => setShowDialog(false)}
                PaperProps={{
                    style: {
                        width: "120%",
                        maxHeight: "90vh",
                    },
                }}
                maxWidth="md"
                fullWidth={true}
            >
                <Typography
                    variant="h3"
                    component="h1"
                    color={"primary"}
                    style={{ textAlign: "center", marginBottom: "20px" }}
                >
                    Notification Centre
                </Typography>
                <NotificationList expandable={true}></NotificationList>
            </Dialog>
        </>
    );
};
