import React, { useState, ChangeEvent, useEffect } from "react";
import {
    Typography,
    TextField,
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    ThemeProvider,
    createTheme,
} from "@material-ui/core";
import { SecondaryButton, DestructiveButton } from "./../../components/layout/Button/Button";
import { useClassroom } from "./contexts/classroom.context";
import { verbalTheme } from "../layout/themes/verbal.theme";
import { EmotionTimeframe } from "./entities/EmotionTimeframe";

interface ThresholdSettings {
    emotionCount: number;
    timeframeFrequency: number;
    timeframeUnit: EmotionTimeframe;
}

export const ManageThresholdScreen = (): JSX.Element => {
    const [thresholdSettings, setThresholdSettings] = useState<ThresholdSettings | null>(null);
    const [showDialog, setShowDialog] = useState(false);

    const { updateEmotionThreshold, getEmotionThreshold, classroom, threshold } = useClassroom();
    const [emotionCount, setEmotionCount] = useState<number | null>(null);
    const [timeframeFrequency, setTimeframeFrequency] = useState<number | null>(null);
    const [timeframeUnit, setTimeframeUnit] = useState<EmotionTimeframe | null>(null);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (classroom) {
            getEmotionThreshold(classroom.id).finally(() => setLoading(false));
        }
    }, [classroom, getEmotionThreshold]);

    useEffect(() => {
        if (threshold) {
            setEmotionCount(threshold.emotionCount);
            setTimeframeFrequency(threshold.emotionFrequency);
            setTimeframeUnit(threshold.emotionTimeframe as EmotionTimeframe);
        }
    }, [threshold]);

    if (loading) {
        return <CircularProgress />;
    }

    const handleSubmitButton = () => {
        if (emotionCount !== null && timeframeFrequency !== null && timeframeUnit !== null) {
            const newSettings: ThresholdSettings = {
                emotionCount,
                timeframeFrequency,
                timeframeUnit,
            };
            setThresholdSettings(newSettings);
            setShowDialog(true);
        }
    };

    const handleConfirmButton = () => {
        if (classroom && thresholdSettings) {
            updateEmotionThreshold(
                classroom.id,
                thresholdSettings?.emotionCount,
                thresholdSettings?.timeframeFrequency,
                thresholdSettings?.timeframeUnit,
            );
        }
        setShowDialog(false);
        setEditMode(false);
    };

    const handleEditButton = () => {
        setEditMode(true);
    };

    const handleCancelButton = () => {
        setEditMode(false);
    };

    const theme = createTheme({
        ...verbalTheme,
        overrides: {
            MuiSelect: {
                selectMenu: {
                    height: "1.5em",
                    verticalAlign: "middle",
                    fontFamily: "Montserrat",
                    fontSize: "1.2em",
                },
            },
        },
    });

    return (
        <div>
            <Dialog open={showDialog !== false} onClose={() => setShowDialog(false)}>
                <DialogTitle>{"Confirm Update"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to change your emotion threshold settings?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleConfirmButton();
                        }}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Box
                padding={2}
                component="form"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                    height: "200%",
                    contain: "layout style paint",
                }}
            >
                <Typography variant="h4" component="p">
                    I would like to be notified if a child has inputted
                    {editMode ? (
                        <TextField
                            value={emotionCount?.toString() || ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmotionCount(Number(e.target.value))}
                            type="number"
                            inputProps={{ min: 0, max: 20, style: { fontSize: "180%" } }}
                            style={{ width: "3.5%", verticalAlign: "middle", padding: "0.1% 0.5%" }}
                        />
                    ) : (
                        " " + emotionCount?.toString() + " "
                    )}
                    negative emotions within a timeframe of
                    {editMode ? (
                        <TextField
                            value={timeframeFrequency?.toString() || ""}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setTimeframeFrequency(Number(e.target.value))
                            }
                            type="number"
                            inputProps={{ min: 0, max: 20, style: { fontSize: "180%" } }}
                            style={{ width: "3.5%", verticalAlign: "middle", padding: "0.1% 0.5%", height: "20%" }}
                        />
                    ) : (
                        " " + timeframeFrequency?.toString() + " "
                    )}
                    {editMode ? (
                        <ThemeProvider theme={theme}>
                            <FormControl>
                                <InputLabel id="timeframe-unit-label"></InputLabel>
                                <Select
                                    labelId="timeframe-unit-label"
                                    id="timeframe-unit-select"
                                    value={timeframeUnit || ""}
                                    onChange={(e: ChangeEvent<{ value: unknown }>) =>
                                        setTimeframeUnit(e.target.value as EmotionTimeframe)
                                    }
                                    style={{ height: "2.3em" }}
                                    displayEmpty
                                >
                                    {Object.entries(EmotionTimeframe).map(([value, label]) => {
                                        return (
                                            <MenuItem key={label} value={label}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </ThemeProvider>
                    ) : (
                        timeframeUnit
                    )}
                </Typography>
            </Box>
            {editMode ? (
                <Box>
                    <SecondaryButton onClick={() => handleSubmitButton()} style={{ marginRight: "0.6em" }}>
                        Confirm
                    </SecondaryButton>
                    <DestructiveButton onClick={() => handleCancelButton()}>Cancel</DestructiveButton>
                </Box>
            ) : (
                <SecondaryButton onClick={() => handleEditButton()}>Edit</SecondaryButton>
            )}
        </div>
    );
};
