import React from "react";
import { Switch } from "react-router-dom";
import Login from "../auth/Login";
import VAlert from "../layout/Alert";
import { BaseRoute } from "./PrivateRoute";
import Dashboard from "../dashboard/Dashboard";
import PermissionDenied from "./PermissionDenied";
import { ProgrammesScreen } from "../programme/screens/ProgrammesScreen";
import { LayoutWithNav, LayoutWithoutNav } from "../layout/BaseLayout";
import { CreateOrganisationPage } from "../../pages/createOrganisationPage";
import { ComponentLibrary } from "../layout/ComponentLibrary";
import { UsersScreen } from "../users/UsersScreen";
import { SessionModeScreen } from "../session/sessionMode/SessionModeScreen";
import { PrepareSession } from "../session/prepareSession/PrepareSession";
import { AuthenticationLayout } from "../layout/auth/AuthenticationLayout";
import { ResetPassword, SetPassword } from "../auth/SetPassword";
import { Forgot } from "../auth/Forgot";
import { EditSessionScreen } from "../session/components/EditSessionPage";
import { StoriesScreen } from "../curation/stories/screens/StoriesScreen";
import { CollectionsScreen } from "../curation/collections/screens/CollectionsScreen";
import { CurriculumsScreen } from "../curation/curriculums/CurriculumsScreen";
import { ThemesScreen } from "../curation/themes/ThemesScreen";
import { StatsProvider } from "../stats/stats";
import { MapStory } from "../curation/stories/map/MapStory";
import { FetchProvider } from "../../core/components/crud/contexts/fetch.context";
import { getMapped } from "../../client/api/story";
import { ViewCollection } from "../home/ViewCollection";
import { ViewCollectionStory } from "../home/ViewCollectionStory";
import { ViewCollectionTheme } from "../home/ViewCollectionTheme";
import { ViewCollectionTopic } from "../home/ViewCollectionTopic";
import { ViewCurriculum } from "../home/ViewCurriculum";
//import { HomeScreen } from "../home/HomeScreen";
import { ViewOnMobile } from "../layout/ViewOnMobile";
import { AddUserScreen } from "../users/AddUserScreen";
import { ClassroomScreen } from "../classroom/ClassroomScreen";
import { useAuth } from "../../contexts/auth.context";
import { ClassSessionScreen } from "../classroom/session/ClassSessionScreen";
import { ExploreScreen } from "../home/ExploreScreen";
import SubscriptionSuccess from "../organisation/onboarding/SubscriptionSuccess";
import { PublicLayout } from "../layout/public/PublicLayout";

const Routes = (): React.ReactElement => {
    const { user } = useAuth();
    return (
        <>
            <VAlert />
            <Switch>
                {process.env.NODE_ENV === "development" && (
                    <BaseRoute layout={LayoutWithNav} path="/component-library" exact component={ComponentLibrary} />
                )}
                <BaseRoute layout={PublicLayout} path="/subscription/success" component={SubscriptionSuccess} />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/explore"
                    exact
                    component={ExploreScreen}
                    permission="DashboardView"
                />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/explore/curriculum/:id"
                    exact
                    component={ViewCurriculum}
                    permission="DashboardView"
                />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/explore/curriculum/:id/collection/:collectionId"
                    exact
                    component={ViewCollection}
                    permission="DashboardView"
                />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/explore/collection/:id/story/:storyId"
                    exact
                    component={ViewCollectionStory}
                    permission="DashboardView"
                />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/explore/collection/:id/theme/:themeId"
                    exact
                    component={ViewCollectionTheme}
                    permission="DashboardView"
                />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/explore/collection/:id/topic/:topicId"
                    exact
                    component={ViewCollectionTopic}
                    permission="DashboardView"
                />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/programmes"
                    component={ProgrammesScreen}
                    permission="ProgrammeViewAll"
                />
                <BaseRoute layout={LayoutWithNav} path="/home" component={Dashboard} permission="DashboardView" />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/add-users"
                    component={AddUserScreen}
                    permission="FacilitatorViewAll"
                />
                <BaseRoute layout={LayoutWithNav} path="/users" component={UsersScreen} permission="SettingsView" />
                <BaseRoute layout={LayoutWithNav} path="/403" component={PermissionDenied} />
                <BaseRoute layout={AuthenticationLayout} path="/login" component={Login} />
                <BaseRoute layout={AuthenticationLayout} path="/create-account/:token" component={SetPassword} />
                <BaseRoute layout={AuthenticationLayout} path="/forgot/" component={Forgot} />
                <BaseRoute layout={AuthenticationLayout} path="/reset/:token" component={ResetPassword} />
                <BaseRoute
                    layout={AuthenticationLayout}
                    path="/organisation/signup"
                    component={CreateOrganisationPage}
                />
                <BaseRoute
                    layout={LayoutWithoutNav}
                    path="/start-session/:id"
                    exact
                    component={SessionModeScreen}
                    permission="ProgrammeViewAll"
                />
                <BaseRoute
                    layout={LayoutWithoutNav}
                    path="/classrooms/start-session/:id"
                    exact
                    component={ClassSessionScreen}
                    permission={"ClassroomView"}
                />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/prepare-session/:id"
                    exact
                    component={PrepareSession}
                    permission="ProgrammeViewAll"
                />
                <BaseRoute
                    layout={LayoutWithNav}
                    path="/sessions/edit/:id"
                    exact
                    component={EditSessionScreen}
                    permission="SessionUpdate"
                />
                <BaseRoute component={StoriesScreen} layout={LayoutWithNav} path="/stories" permission="StoryViewAll" />
                <BaseRoute
                    component={CollectionsScreen}
                    layout={LayoutWithNav}
                    path="/collections"
                    permission="StoryCollectionView"
                />
                <BaseRoute
                    component={CurriculumsScreen}
                    layout={LayoutWithNav}
                    path="/curriculums"
                    permission="StoryCollectionCreate"
                />
                <BaseRoute
                    component={ThemesScreen}
                    layout={LayoutWithNav}
                    path="/themes"
                    permission="StoryCollectionCreate"
                />
                <BaseRoute path={"/detect-mobile"} exact layout={LayoutWithoutNav} component={ViewOnMobile} />
                <BaseRoute
                    component={ClassroomScreen}
                    layout={LayoutWithNav}
                    path="/classrooms"
                    permission={user?.isSchool ? "ClassroomView" : undefined}
                />
                <BaseRoute component={StatsProvider} layout={LayoutWithNav} path="/stats" permission="StatsViewAll" />
                {/** Keep this route last as routes after the fetch provider don't seem to work**/}
                <FetchProvider noun="Story" getItem={getMapped}>
                    <BaseRoute
                        component={MapStory}
                        layout={LayoutWithNav}
                        path={"/map/story/:id"}
                        permission="StoryCollectionView"
                    />
                </FetchProvider>
            </Switch>
        </>
    );
};

export default Routes;
